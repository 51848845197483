import React, { useState } from "react";
import { useContext } from "react";

const LayoutContext = React.createContext(
  null as unknown as {
    hideSidebar: boolean;
    setHideSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  }
);

export const useLayout = () => useContext(LayoutContext);

export const LayoutProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  const [hideSidebar, setHideSidebar] = useState(false);

  return (
    <LayoutContext.Provider value={{ hideSidebar, setHideSidebar }}>
      {children}
    </LayoutContext.Provider>
  );
};
