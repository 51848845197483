import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useLocalStorage } from "react-use";
import { auth, googleProvider } from "../../firebaseConfig";
import { useApiFetch } from "../api";
import { toast } from "react-toastify";
type User = {
  email: string;
};

// type RegisterData = {
//   email: string;
//   password: string;
//   passwordConfirmation: string;
// };

// type LoginData = {
//   email: string;
//   password: string;
// };

type ContextType = {
  user?: User | null;
  // eslint-disable-next-line no-unused-vars
  login: (token: boolean) => Promise<void>;
  logout: () => void;
};

const AuthContext = createContext(null as unknown as ContextType);

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [user, setUser] = useLocalStorage<User | null>("GETSLIDES_USER", null);
  const [tokenValid, setTokenValid] = useState<boolean>();
  const { fetchApi } = useApiFetch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) return;
      const apiResponse = await fetchApi(`/api/user?email=${user.email}`);
      const foundUser = await apiResponse.json();
      if (!foundUser.user) {
        if (tokenValid) {
          const response = await fetchApi("/api/user", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ email: user.email })
          });
          const newUser = (await response.json()) as User;
          if (newUser) {
            setUser(newUser);
            toast("Sie sind erfolgreich eingeloggt!", {
              type: "success"
            });
          }
        } else {
          toast("Sie sind nicht berechtigt, sich anzumelden!", {
            type: "error"
          });
          await auth.signOut();
        }
      } else {
        setUser(foundUser.user);
        toast("Sie sind erfolgreich eingeloggt!", {
          type: "success"
        });
      }
    });
    return () => {
      console.log("unsubscribe");
      unsubscribe();
    };
  }, [fetchApi, setUser, tokenValid]);

  const handleLogin = useCallback(async (isTokenValid: boolean) => {
    setTokenValid(isTokenValid);
    auth.signInWithPopup(googleProvider).catch((error) => {
      console.log(error.message);
    });
  }, []);

  const handleLogout = useCallback(() => {
    console.log("logout");
    setUser(null);
  }, [setUser]);

  return (
    <AuthContext.Provider
      value={{
        user,
        login: handleLogin,
        logout: handleLogout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
