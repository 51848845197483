/* eslint-disable no-undef */
import { faImage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ComponentType } from "../types";
import { H1, H2, H3, P } from "./text";
import { Dynamic } from "./dynamic";
import { ImageComp } from "./image";

import logo from "./assets/solongo_logo.png";

export type Template = {
  name: string;
  type: ComponentType;
  defaultData: any;
  component: any;
  previewComponent: JSX.Element;
};

export const components: { label: string; templates: Template[] }[] = [
  {
    label: "Text Elemente",
    templates: [
      {
        name: "Überschrift 1",
        type: ComponentType.TEXT_H1,
        defaultData: {
          value: "Überschrift 1",
          horizontalAlign: "left",
          verticalAlign: "top"
        },
        component: H1,
        previewComponent: <span style={{ fontSize: 26 }}>Überschrift 1</span>
      },
      {
        name: "Überschrift 2",
        type: ComponentType.TEXT_H2,
        defaultData: {
          value: "Überschrift 2",
          horizontalAlign: "left",
          verticalAlign: "top"
        },

        component: H2,
        previewComponent: <span style={{ fontSize: 22 }}>Überschrift 2</span>
      },
      {
        name: "Normaler Fließtext",
        type: ComponentType.TEXT_P,
        defaultData: {
          value: "Text",
          horizontalAlign: "left",
          verticalAlign: "top"
        },
        component: P,
        previewComponent: (
          <div style={{ fontSize: 14 }}>
            Normaler Fließtext, wie man ihn in jedem längeren Text benutzen
            würde.
          </div>
        )
      },
      {
        name: "Kleiner Text",
        type: ComponentType.TEXT_SMALL,
        defaultData: {
          value: "Text",
          horizontalAlign: "left",
          verticalAlign: "top"
        },
        component: P,
        previewComponent: (
          <div style={{ fontSize: 10 }}>
            Kleiner Text. z.B. für Erklärungen an Grafiken
          </div>
        )
      }
    ]
  },
  {
    label: "Dynamische Elemente",
    templates: [
      {
        name: "Pim Artikeldaten",
        type: ComponentType.DYNAMIC,
        defaultData: {
          elements: []
        },
        component: Dynamic,
        previewComponent: (
          <div className="small">
            <div>Artikeldaten</div>
            <div
              className="d-inline-block bg-dark me-1"
              style={{ width: 50, height: 10 }}
            />
            <div
              className="d-inline-block bg-dark me-1"
              style={{ width: 20, height: 10 }}
            />
            <div
              className="d-inline-block bg-dark me-1"
              style={{ width: 30, height: 10 }}
            />
            <div
              className="d-inline-block bg-dark me-1"
              style={{ width: 15, height: 10 }}
            />
            <div
              className="d-inline-block bg-dark me-1"
              style={{ width: 45, height: 10 }}
            />
            <div
              className="d-inline-block bg-dark me-1"
              style={{ width: 25, height: 10 }}
            />
            <div
              className="d-inline-block bg-dark me-1"
              style={{ width: 10, height: 10 }}
            />
            <div
              className="d-inline-block bg-dark me-1"
              style={{ width: 55, height: 10 }}
            />
          </div>
        )
      }
    ]
  },
  {
    label: "Bilder & Grafiken",
    templates: [
      {
        name: "Einfaches Bild",
        type: ComponentType.IMAGE,
        defaultData: {
          name: "solongo_logo.png",
          src: logo,
          handle: "",
          innerStyleEdited: false,
          innerStyle: { top: 0, left: 0, width: 100, height: 100 }
        },
        component: ImageComp,
        previewComponent: (
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              style={{
                fontSize: 24
              }}
              icon={faImage}
            />
            <span className="small ms-2">Bild einfach</span>
          </div>
        )
      }
    ]
  }
];
