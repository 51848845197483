import React, { useCallback } from "react";
import { useContext } from "react";

const ApiFetchContext = React.createContext(
  null as unknown as {
    fetchApi: (...[path, opts]: Parameters<typeof fetch>) => Promise<Response>;
  }
);

let BASE_URL = "http://localhost:5001/getslides-e7e5d/europe-west3";
// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === "production") {
  BASE_URL = "https://europe-west3-getslides-e7e5d.cloudfunctions.net";
}

export const useApiFetch = () => useContext(ApiFetchContext);

export const ApiFetchProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  const fetchApi = useCallback(
    async (...[path, opts]: Parameters<typeof fetch>) =>
      fetch(BASE_URL + path, opts),
    []
  );
  return (
    <ApiFetchContext.Provider value={{ fetchApi }}>
      {children}
    </ApiFetchContext.Provider>
  );
};
