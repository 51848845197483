import React, { CSSProperties, useEffect, useRef } from "react";

type SlideThumbProps = {
  className?: string;
  style?: CSSProperties;
  src: string;
};

const SlideThumb: React.FC<React.PropsWithChildren<SlideThumbProps>> = ({
  src,
  className,
  style
}) => {
  const thumbRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!thumbRef.current) return;
    const image = new Image();
    const url = src;
    image.onload = () => {
      if (thumbRef.current) {
        thumbRef.current.src = url;
      }
    };
    image.src = url;
  }, [src, thumbRef]);

  return <img ref={thumbRef} className={className} style={style} />;
};

export default SlideThumb;
