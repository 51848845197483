import React, { cloneElement, useEffect } from "react";
import { useRef, useState } from "react";
import {
  useDispatchContext,
  useStateContext
} from "../../context/state/provider";
import Draggable from "react-draggable";
import { StateCustomSlideComponent } from "../../types";
import "./index.css";
import TransformationFrame from "./components/TransformationFrame";
import { cloneDeep } from "lodash";

type EditorCompProps = {
  element: StateCustomSlideComponent;
  scale: number;
  slideNode: HTMLDivElement;
  handleResizeStart: (e: any) => void;
};

// eslint-disable-next-line no-undef
const EditorComponent = ({
  element,
  scale,
  slideNode,
  handleResizeStart
}: EditorCompProps) => {
  const {
    state: {
      editor: { selectedSlideComponent }
    }
  } = useStateContext();
  const { dispatch } = useDispatchContext();
  const Component = element.component;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    const { offsetWidth: width, offsetHeight: height } = ref.current
      .firstChild as HTMLElement;
    const componentCopy = cloneDeep(element);
    componentCopy.dimensions = {
      width,
      height
    };
    dispatch({ type: "updateComponent", data: componentCopy });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ref]);

  const handleSelectElement = (id: string) => {
    dispatch({ type: "selectComponent", data: id });
  };

  const handleUpdateElementPosition = (newPosition: {
    x: number;
    y: number;
  }) => {
    const componentCopy = cloneDeep(element);
    componentCopy.position = newPosition;
    dispatch({ type: "updateComponent", data: componentCopy });
  };

  const selected = element.id === selectedSlideComponent?.id;

  return (
    <Draggable
      nodeRef={ref}
      bounds="parent"
      scale={scale}
      grid={[10, 10]}
      position={element.position || { x: 0, y: 0 }}
      enableUserSelectHack={false}
      defaultClassName={`editor-component ${selected ? "selected" : ""}`.trim()}
      onStart={() => handleSelectElement(element.id)}
      onStop={(e, j) => {
        handleUpdateElementPosition({ x: j.lastX, y: j.lastY });
      }}
    >
      <div ref={ref} style={{ display: "inline-block", position: "relative" }}>
        <Component
          data={element.data}
          dimensions={element.position}
          transform={element.transform}
          style={element.style}
          onChange={() => {}}
          scale={scale}
        />
        {selected && (
          <TransformationFrame handleResizeStart={handleResizeStart} />
        )}
      </div>
    </Draggable>
  );
};

export default EditorComponent;
