import { CSSProperties, FC, SyntheticEvent } from "react";
import { ImageData } from "../types";

import logo from "./assets/solongo_logo.png";

type ImageProps = {
  data: ImageData;
  onChange: (newData: ImageData) => void;
};

const imageStyle: CSSProperties = {
  position: "relative",
  objectFit: "contain"
};

export const ImageComp = ({ data, onChange }: ImageProps) => {
  const handleLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    if (data.sizeChanged) return;
    const naturalDimensions = {
      width: e.currentTarget.naturalWidth,
      height: e.currentTarget.naturalHeight
    };
    onChange({ ...data, naturalDimensions });
  };

  return (
    <img
      style={{ ...imageStyle, ...data.naturalDimensions }}
      draggable={false}
      src={data.src || logo}
      alt={data.name}
      title={data.name}
      onLoad={handleLoad}
    />
  );
};
