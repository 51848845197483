import { initialState } from "../provider";
import { Action, PresentationBuilderAction, Reducer, State } from "../types";

export const presentationBuilderReducer: Reducer<
  State["presentationBuilder"],
  Action
> = (state = initialState.presentationBuilder, action) => {
  action = action as PresentationBuilderAction;
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log(
      `%cPRESENTATION_BUILDER REDUCER ACTION: ${action.type} `,
      "background-color:black;color:yellow",
      action
    );
  }
  switch (action.type) {
    case "setMasterPresentations":
      state.masterPresentations = action.data;
      break;
    case "setMasterPresentationSlides":
      state.selectedMasterPresentationSlides = action.data;
      break;
    case "setSelectedMasterPresentation":
      state.selectedMasterPresentation = action.data;
      break;
    case "setSelectedMasterPresentationSlide":
      state.selectedMasterSlide = action.data;
      break;
    case "setCustomPresentations":
      state.customPresentations = action.data;
      break;
    case "setCustomPresentationSlides":
      state.selectedCustomPresentationSlides = action.data;
      break;
    case "setSelectedCustomPresentation":
      state.selectedCustomPresentation = action.data;
      break;
    case "setSelectedCustomPresentationSlide":
      state.selectedCustomSlide = action.data;
      break;
    case "setNewCustomPresentation":
      state.newCustomPresentation = action.data;
      break;
    case "setNewCustomPresentationSlides":
      state.newCustomPresentationSlides = action.data;
      break;
    case "setShowConfirmationModal":
      state.modalData = action.data;
      break;
    case "setHideConfirmationModal":
      state.modalData = null;
  }
  return state;
};
