import React, { CSSProperties } from "react";

export enum ComponentType {
  TEXT_H1 = "TEXT_H1",
  TEXT_H2 = "TEXT_H2",
  TEXT_H3 = "TEXT_H3",
  TEXT_P = "TEXT_P",
  TEXT_SMALL = "TEXT_SMALL",
  DYNAMIC = "DYNAMIC",
  IMAGE = "IMAGE"
}

export enum SlideType {
  EXPORTED = "EXPORTED",
  CONSTRUCTED = "CONSTRUCTED"
}

export type TextData = {
  value: string;
  verticalAlign: "top" | "bottom";
  horizontalAlign: "left" | "right" | "center" | "justify";
};

export type ImageData = {
  name?: string;
  src?: string;
  handle?: string;
  sizeChanged: boolean;
  naturalDimensions?: {
    width: number;
    height: number;
  };
};

export type DynamicData = Record<string, any>;

export type StateCustomSlideComponent = CustomSlideComponent & {
  // eslint-disable-next-line no-undef
  component: (props: any) => React.ReactElement;
};

export type CustomSlideComponent = {
  id: string;
  type: ComponentType;
  position: { x: number; y: number } | null;
  dimensions: {
    width: number;
    height: number;
  } | null;
  transform: {
    scale: number;
    rotate: number;
  } | null;
  style: CSSProperties;
  order: number | null;
  data: TextData | ImageData | DynamicData;
};

export type CustomBuiltSlideData = {
  id: string;
  slideId: string;
  createdAt: string;
  updatedAt: string;
  src: string;
  initialSlideDimensions: {
    width: number;
    height: number;
  } | null;
  components: StateCustomSlideComponent[];
  type: SlideType.CONSTRUCTED;
};

export type CustomPresentationSlideData = {
  slideId: string;
  orderIndex: number;
  id: string;
};

export type CustomPresentationInput = {
  id: string;
  title: string;
  created: string;
  updated: string;
  thumbnail: string;
  slides: CustomPresentationSlideData[];
};

export type SlideData = {
  presentationId: string;
  presentationTitle: string;
  slideId: string;
  id: string;
  masterIndex: number;
  created: string;
  version: number;
  src: string;
  type: SlideType.EXPORTED;
};

export type PresentationData = {
  id: string;
  title: string;
  uniqueSlides: number;
  totalSlides: number;
  thumbnail: string;
  created: string;
  updated: string;
};
