import React from "react";

const Navbar: React.FC<
  React.PropsWithChildren<{
    mainContent: React.ReactNode;
  }>
> = ({ mainContent }) => {
  return (
    <div className="toolbar">
      <div className="main-content">{mainContent}</div>
    </div>
  );
};

export default Navbar;
