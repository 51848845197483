import "./index.css";
import { components, Template } from "../../constants/components";
import Accordion from "../ui/Accordion";
import { useCallback } from "react";
import { StateCustomSlideComponent } from "../../types";
import { v4 } from "uuid";
import { useDispatchContext } from "../../context/state/provider";

function EditorSidebar() {
  const { dispatch } = useDispatchContext();

  const handleElementSelect = useCallback(
    (template: Template) => {
      const newStateComponent: StateCustomSlideComponent = {
        component: template.component,
        data: template.defaultData,
        id: v4(),
        dimensions: { width: 0, height: 0 },
        position: { x: 0, y: 0 },
        style: {},
        transform: null,
        type: template.type,
        order: null
      };
      dispatch({ type: "addNewComponent", data: newStateComponent });
    },
    [dispatch]
  );

  return (
    <div className="editor-sidebar bg-white shadow-lg">
      {components.map((group, idx) => (
        <Accordion
          key={idx}
          title={
            <p className="m-0" style={{ fontSize: "1.1rem" }}>
              {group.label}
            </p>
          }
          content={group.templates.map((t) => ({
            previewComponent: t.previewComponent,
            onClick: () => handleElementSelect(t)
          }))}
        />
      ))}
    </div>
  );
}

export default EditorSidebar;
