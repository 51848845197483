import firebase from "firebase";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBvUUV2K11djCUy9AhZYTI7Wyv92zkBcGs",
  authDomain: "getslides-e7e5d.firebaseapp.com",
  projectId: "getslides-e7e5d",
  storageBucket: "getslides-e7e5d.appspot.com",
  messagingSenderId: "403467227600",
  appId: "1:403467227600:web:9321e71265981b1551a9d5"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider();

export { auth, googleProvider };
