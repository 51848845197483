import { useEffect } from "react";
import { createPortal } from "react-dom";
import {
  useDispatchContext,
  useStateContext
} from "../../context/state/provider";
import "./index.css";

const Modal = () => {
  const {
    state: {
      presentationBuilder: { modalData }
    }
  } = useStateContext();
  const { dispatch } = useDispatchContext();
  return (
    <div className="backdrop">
      <div className="modal-wrapper shadow-lg">
        <div className="modal-header border-bottom p-0">
          <h3>{modalData?.headerText}</h3>
        </div>
        <p className="modal-text mt-3">{modalData?.mainText}</p>
        <div className="modal-footer d-flex justify-content-end p-0">
          <button
            className="btn btn-secondary"
            onClick={() => dispatch({ type: "setHideConfirmationModal" })}
          >
            Abbrechen
          </button>
          <button
            className="btn btn-primary ms-2"
            onClick={modalData?.onConfirm}
          >
            Bestätigen
          </button>
        </div>
      </div>
    </div>
  );
};

const PortalModal = () => {
  const portalContainer = document.createElement("div");
  portalContainer.id = "modal-container";
  document.body.appendChild(portalContainer);

  useEffect(() => {
    return () => {
      if (portalContainer) document.body.removeChild(portalContainer);
    };
  }, [portalContainer]);

  return createPortal(<Modal />, portalContainer);
};

export default PortalModal;
