import { useCallback, useEffect, useRef, useState } from "react";
import { useSize } from "react-use";
import {
  useDispatchContext,
  useStateContext
} from "../../context/state/provider";
import EditorComponent from "../EditorComponent";
import "./index.css";

const EditorSlide = () => {
  const {
    state: {
      editor: { customSlide }
    }
  } = useStateContext();
  const { dispatch } = useDispatchContext();
  const editorRef = useRef<HTMLDivElement>(null);
  const [elementsScale, setElementsScale] = useState(1);

  useEffect(() => {
    if (!customSlide || !editorRef.current) return;
    if (!customSlide.initialSlideDimensions) {
      dispatch({
        type: "updateEditorSlide",
        data: {
          ...customSlide,
          initialSlideDimensions: {
            width: editorRef.current.offsetWidth,
            height: editorRef.current.offsetHeight
          }
        }
      });
    } else {
      setElementsScale(
        editorRef.current.offsetWidth / customSlide.initialSlideDimensions.width
      );
    }
  }, [customSlide, dispatch]);

  useEffect(() => {
    if (!customSlide || editorRef.current) return;
    const newComponents = customSlide.components.filter(
      (c) => !c.dimensions && !c.position && !c.transform
    );
    newComponents.forEach((comp) => {
      comp.position = {
        x: 0,
        y: 0
      };
    });
  }, [customSlide, customSlide?.components]);

  const handleResizeStart = useCallback((e: any) => {
    e.stopPropagation();
  }, []);

  // useEffect(() => {
  //   if (
  //     !customSlide ||
  //     !customSlide.components.length ||
  //     !customSlide.initialSlideDimensions
  //   )
  //     return;
  //   setElementsScale(width / customSlide.initialSlideDimensions.width);
  // }, [customSlide, width]);

  if (!customSlide) return null;

  return (
    <div className="editor-slide" ref={editorRef}>
      {editorRef.current &&
        customSlide?.components.map((elem) => (
          <EditorComponent
            element={elem}
            key={elem.id}
            scale={elementsScale}
            handleResizeStart={handleResizeStart}
            slideNode={editorRef.current as HTMLDivElement}
          />
        ))}
    </div>
  );
};

export default EditorSlide;
