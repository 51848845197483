import { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useAuth } from "./context/auth/provider";
import Auth from "./views/Auth";
import Editor from "./views/Editor";
import PresentationBuilder from "./views/PresentationBuilder";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/*
  http://localhost:3000/register/ybEZgnt71TBPtFCHfVIErKYotRMSwY3n - invitation link with token
*/

function App() {
  const { user } = useAuth();
  const history = useHistory();
  useEffect(() => {
    if (!user && history.location.pathname === "/") {
      history.replace("/login");
    }
  }, [history, user]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Slide}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Switch>
        <Route exact path="/login" component={Auth} />
        <Route exact path="/register/:token" component={Auth} />
        <Route path="/editor" component={Editor} />
        {user && <Route path="/" exact component={PresentationBuilder} />}
      </Switch>
    </>
  );
}

export default App;
