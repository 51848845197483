import { initialState } from "../provider";
import { Action, EditorAction, Reducer, State } from "../types";

export const editorReducer: Reducer<State["editor"], Action> = (
  state = initialState.editor,
  action
) => {
  action = action as EditorAction;
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log(
      `%cEDITOR REDUCER ACTION: ${action.type} `,
      "background-color:black;color:yellow",
      action
    );
  }
  switch (action.type) {
    case "initEditorSlide":
      state = {
        ...action.data,
        selectedCustomPresentationSlides: [
          ...action.data.selectedCustomPresentationSlides,
          action.data.customSlide
        ]
      };
      break;
    case "resetEditorSlide":
      state = {
        customSlide: null,
        selectedCustomPresentation: null,
        selectedCustomPresentationSlides: [],
        selectedSlideComponent: null
      };
      break;
    case "updateEditorSlide":
      state.customSlide = action.data;
      break;
    case "addNewComponent": {
      if (!state.customSlide) break;
      const lastOrder = Math.max(
        ...state.customSlide.components.map((c) => c.order ?? 0)
      );
      const newComponent = {
        ...action.data,
        order: lastOrder + 1
      };
      state.customSlide.components.push(newComponent);
      state.selectedSlideComponent = newComponent;
      state.customSlide.components = state.customSlide.components.sort(
        (a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          } else return 0;
        }
      );
      break;
    }
    case "selectComponent": {
      const id = action.data;
      if (state.selectedSlideComponent?.id === id) break;
      const foundElement = state.customSlide?.components.find(
        (comp) => comp.id === id
      );
      if (foundElement) {
        state.selectedSlideComponent = foundElement;
      }
      break;
    }
    case "updateComponent": {
      const updatedComponent = action.data;
      const componentExists = state.customSlide?.components.find(
        (comp) => comp.id === updatedComponent.id
      );
      if (!componentExists || !state.customSlide) break;
      state.customSlide.components = state.customSlide.components.map((comp) =>
        comp.id === updatedComponent.id ? updatedComponent : comp
      );
      break;
    }
  }
  return state;
};
