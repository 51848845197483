import {
  faBackward,
  faClipboard,
  faPlay,
  faShareAlt
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useApiFetch } from "../../context/api";
import {
  useDispatchContext,
  useStateContext
} from "../../context/state/provider";
import "./index.css";
import { useHistory, useLocation } from "react-router-dom";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

let FULLMODE_URL_BASE = "http://localhost:3002/presentation";
// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === "production") {
  FULLMODE_URL_BASE = "https://getslides-public.web.app/presentation";
}

enum CurrentView {
  BUILDER,
  EDITOR
}

const getCurrentView = (pathname: string) => {
  switch (pathname) {
    case "/":
      return CurrentView.BUILDER;
    case "/editor":
      return CurrentView.EDITOR;
    default:
      return CurrentView.BUILDER;
  }
};

const Header: React.FC<React.PropsWithChildren<unknown>> = () => {
  const location = useLocation();
  const history = useHistory();
  const [currentView, setCurrentView] = useState<CurrentView>(
    getCurrentView(location.pathname)
  );
  const {
    state: { presentationBuilder }
  } = useStateContext();
  const { dispatch } = useDispatchContext();
  const { fetchApi } = useApiFetch();

  useEffect(() => {
    setCurrentView(getCurrentView(location.pathname));
  }, [location]);

  const hadnleCopyInviteLink = () => {
    // eslint-disable-next-line no-undef
    const token = process.env.REACT_APP_INVITE_TOKEN;
    if (token) {
      let link = "http://localhost:3000/register/" + token;
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === "production") {
        link = "https://getslides.app/register/" + token;
      }
      navigator.clipboard.writeText(link);
    }
  };

  const handleOpenPresentation = async () => {
    if (!presentationBuilder.selectedCustomPresentation) return;
    const presentationId = presentationBuilder.selectedCustomPresentation.id;
    const response = await fetchApi(`/api/generate-token/${presentationId}`);
    const token = await response.json();
    console.log(token);
    window.open(`${FULLMODE_URL_BASE}/${token.token}`, "_blank")?.focus();
  };

  const generateAndCopyLink = async () => {
    if (!presentationBuilder.selectedCustomPresentation) return;
    const presentationId = presentationBuilder.selectedCustomPresentation.id;
    const response = await fetchApi(`/api/generate-token/${presentationId}`);
    const token = await response.json();
    console.log(token);
    const link = `${FULLMODE_URL_BASE}/${token.token}`;
    navigator.clipboard.writeText(link);
  };

  const handleExitEditorMode = () => {
    history.push("/");
    dispatch({ type: "resetEditorSlide" });
  };

  return (
    <div className="header">
      <div className="logo">
        <h3>getslides.app</h3>
      </div>
      {currentView === CurrentView.BUILDER && (
        <>
          <div
            className="d-flex"
            style={{ flex: 1, justifyContent: "flex-end" }}
          >
            <div className="control-section-left">
              <button
                className="btn btn-secondary p-2
                 d-flex justify-content-center align-items-center ms-2"
                onClick={hadnleCopyInviteLink}
                style={{ height: 38 }}
              >
                <span>Invitation Link</span>
                <FontAwesomeIcon icon={faShareAlt} className="ms-2" />
              </button>
            </div>
            <div
              className="bg-secondary"
              style={{ height: "85%", width: 1, margin: "0 10px" }}
            />
            <div className="control-section-right">
              {!!presentationBuilder.selectedCustomPresentation && (
                <div className="d-flex">
                  <button
                    className="btn btn-secondary"
                    onClick={generateAndCopyLink}
                  >
                    <span className="me-2">Link Kopieren</span>
                    <FontAwesomeIcon icon={faClipboard} />
                  </button>
                  <button
                    className="btn btn-primary ms-2"
                    onClick={handleOpenPresentation}
                  >
                    <span className="me-2">Präsentation öffnen</span>
                    <FontAwesomeIcon icon={faPlay} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {currentView === CurrentView.EDITOR && (
        <div>
          <button className="btn btn-danger" onClick={handleExitEditorMode}>
            <span className="me-2">Editor Schließen</span>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;
