import React, { useEffect, useState } from "react";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faTimes,
  faPen,
  faCheck,
  faTrashAlt
} from "@fortawesome/pro-regular-svg-icons";
import { CustomPresentationInput, PresentationData } from "../../types";
import SlideThumb from "../SlideThumb";

type PresentationSelectProps = {
  onSelect: (data: PresentationData | CustomPresentationInput) => void;
  items: PresentationData[] | CustomPresentationInput[];
  selectedItem: PresentationData | CustomPresentationInput | null;
  additionalActionTile?: { title: string; onClick: () => void };
  itemType?: "pill" | "thumb";
  onDelete?: (
    item: PresentationData | CustomPresentationInput
  ) => void | Promise<void>;
  onCopy?: (
    item: PresentationData | CustomPresentationInput
  ) => void | Promise<void>;
  onTitleEdit?: (
    item: PresentationData | CustomPresentationInput,
    newTitle: string
  ) => void | Promise<void>;
};

const PresentationSelect: React.FC<
  React.PropsWithChildren<PresentationSelectProps>
> = ({
  onSelect,
  items,
  itemType = "thumb",
  selectedItem,
  additionalActionTile,
  onDelete,
  onCopy,
  onTitleEdit
}) => {
  const [showDeleteIcon, setShowDeleteIcon] = useState<string | null>(null);
  const [showCopyIcon, setShowCopyIcon] = useState<string | null>(null);
  const [showEditIcon, setShowEditIcon] = useState<string | null>(null);
  const [showEditInput, setShowEditInput] = useState(false);
  const [titleToEdit, setTitleToEdit] = useState("");

  useEffect(() => {
    if (showDeleteIcon && selectedItem && showDeleteIcon !== selectedItem.id) {
      setShowDeleteIcon(null);
    }
    if (showCopyIcon && selectedItem && showCopyIcon !== selectedItem.id) {
      setShowCopyIcon(null);
    }
    if (showEditIcon && selectedItem && showEditIcon !== selectedItem.id) {
      setShowEditIcon(null);
      setShowEditInput(false);
    }
  }, [selectedItem, showCopyIcon, showDeleteIcon, showEditIcon]);

  const handleCloseContextMenu = () => {
    setShowDeleteIcon(null);
    setShowCopyIcon(null);
    setShowEditIcon(null);
  };

  const handleContextMenu = (
    item: PresentationData | CustomPresentationInput
  ) => {
    if (selectedItem && selectedItem.id === item.id) {
      if (onDelete) {
        setShowDeleteIcon((prev) => (prev === item.id ? null : item.id));
      }
      if (onCopy) {
        setShowCopyIcon((prev) => (prev === item.id ? null : item.id));
      }
      if (onTitleEdit) {
        setShowEditIcon((prev) => (prev === item.id ? null : item.id));
      }
    }
  };

  return items.length ? (
    <ul
      className={`select-list ${
        itemType === "pill" ? "pills-list" : "thumbs-list"
      }`}
    >
      {items.map((it) =>
        itemType === "thumb" ? (
          <li
            key={it.id}
            className={`select-list-item ${
              it.id === selectedItem?.id ? "active" : ""
            }`}
            onClick={() => {
              onSelect(it);
            }}
            title={it.title}
            onContextMenu={(e) => {
              e.preventDefault();
              handleContextMenu(it);
            }}
          >
            <div className="thumb-image-wrapper position-relative">
              {!showEditInput && (
                <>
                  {!!(showEditIcon === it.id && onTitleEdit) && (
                    <div
                      className="action-icon edit"
                      onClick={() => {
                        setShowEditInput(true);
                        setTitleToEdit(it.title);
                      }}
                      title="Umbenennen"
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </div>
                  )}
                  {!!(showCopyIcon === it.id && onCopy) && (
                    <div
                      className="action-icon copy"
                      onClick={() => onCopy(it)}
                      title="Kopieren"
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    </div>
                  )}
                  {!!(showDeleteIcon === it.id && onDelete) && (
                    <div
                      className="action-icon delete"
                      onClick={() => onDelete(it)}
                      title="Löschen"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  )}
                </>
              )}
              <SlideThumb src={it.thumbnail} className="item-thumb-image" />
            </div>
            {showEditInput && showEditIcon === it.id && onTitleEdit ? (
              <div className="d-flex mt-2">
                <input
                  type="text"
                  className="p-1"
                  style={{ height: 22, width: 90, fontSize: "0.8rem" }}
                  value={titleToEdit}
                  onChange={(e) => setTitleToEdit(e.target.value)}
                />
                <button
                  className="btn btn-success p-0 d-flex align-items-center justify-content-center ms-1"
                  style={{ width: 22, height: 22 }}
                  onClick={async () => {
                    await onTitleEdit(it, titleToEdit);
                    setShowEditInput(false);
                    setTitleToEdit("");
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <button
                  className="btn btn-secondary p-0 d-flex align-items-center justify-content-center ms-1"
                  style={{ width: 22, height: 22 }}
                  onClick={() => {
                    setShowEditInput(false);
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            ) : (
              <p className="item-thumb-title">
                {it.title.length > 14
                  ? it.title.substring(0, 14) + "..."
                  : it.title}
              </p>
            )}
          </li>
        ) : !showEditInput &&
          showEditIcon === it.id &&
          [showCopyIcon, showDeleteIcon, showEditIcon].some((v) => !!v) ? (
          <div
            className="badge bg-secondary d-flex align-items-center justify-content-center"
            style={{ gap: 20, width: 110 }}
            onContextMenu={(e) => {
              e.preventDefault();
              handleCloseContextMenu();
            }}
          >
            {!!onTitleEdit && (
              <span
                className="pill-icon edit"
                onClick={() => {
                  setShowEditInput(true);
                  setTitleToEdit(it.title);
                }}
                title="Umbenennen"
              >
                <FontAwesomeIcon icon={faPen} />
              </span>
            )}
            {!!onCopy && (
              <span
                onClick={() => onCopy(it)}
                title="Kopieren"
                className="pill-icon copy"
              >
                <FontAwesomeIcon icon={faCopy} />
              </span>
            )}
            {!!onDelete && (
              <span
                onClick={() => onDelete(it)}
                title="Löschen"
                className="pill-icon delete text-danger"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </span>
            )}
          </div>
        ) : showEditInput && showEditIcon === it.id && onTitleEdit ? (
          <div className="d-flex" style={{ gap: 5 }}>
            <input
              type="text"
              className="p-1 h-100 rounded"
              style={{ padding: ".35em .65em", fontSize: "0.8rem" }}
              value={titleToEdit}
              onChange={(e) => setTitleToEdit(e.target.value)}
            />
            <button
              className="btn btn-success p-0 rounded-sm d-flex align-items-center justify-content-center"
              style={{ width: 26.5, aspectRatio: "1/1" }}
              onClick={async () => {
                await onTitleEdit(it, titleToEdit);
                setShowEditInput(false);
                setTitleToEdit("");
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </button>
            <button
              className="btn btn-secondary p-0 rounded-sm d-flex align-items-center justify-content-center"
              style={{ width: 26.5, aspectRatio: "1/1" }}
              onClick={() => {
                setShowEditInput(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        ) : (
          <li
            className={`badge bg-${
              it.id === selectedItem?.id ? "success" : "danger"
            } d-flex align-items-center`}
            style={{ fontSize: "1rem", cursor: "pointer" }}
            key={it.id}
            onClick={() => {
              onSelect(it);
            }}
            title={it.title}
            onContextMenu={(e) => {
              e.preventDefault();
              handleContextMenu(it);
            }}
          >
            {it.title}
          </li>
        )
      )}
      {additionalActionTile && (
        <li
          className={`${
            itemType === "pill" ? "badge bg-secondary" : "additional-tile"
          }`}
          style={{ fontSize: "1rem", cursor: "pointer" }}
          onClick={additionalActionTile.onClick}
        >
          {additionalActionTile.title}
        </li>
      )}
    </ul>
  ) : null;
};

export default PresentationSelect;
