import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faChevronDoubleRight,
  faChevronDoubleLeft
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useLayout } from "../../../context/layout";
import { CustomBuiltSlideData, SlideData } from "../../../types";
import SlideThumb from "../../SlideThumb";
import "./index.css";

function Navbar({
  items,
  onSelect,
  activeItem,
  droppableId,
  isDropDisabled,
  headerContent,
  allowDelete,
  onDelete,
  additionalActionTile,
  withDrop = true
}: {
  items: (SlideData | CustomBuiltSlideData)[];
  onSelect: (data: SlideData | CustomBuiltSlideData) => void;
  activeItem: SlideData | CustomBuiltSlideData | null;
  additionalActionTile?: { title: string; onClick: () => void };
  droppableId?: string;
  isDropDisabled: boolean;
  headerContent?: React.ReactNode;
  allowDelete?: boolean;
  onDelete?: (item: SlideData | CustomBuiltSlideData) => void | Promise<void>;
  withDrop?: boolean;
}) {
  const [showDeleteIcon, setShowDeleteIcon] = useState<string | null>(null);
  useEffect(() => {
    if (showDeleteIcon && activeItem && showDeleteIcon !== activeItem.id) {
      setShowDeleteIcon(null);
    }
  }, [activeItem, showDeleteIcon]);
  const { hideSidebar } = useLayout();
  if (hideSidebar) return null;
  return withDrop ? (
    <Droppable droppableId={droppableId || ""} isDropDisabled={isDropDisabled}>
      {(prov) => (
        <>
          <ul
            className="sidebar-list ps-0 bg-white"
            style={headerContent ? { paddingTop: 55 } : {}}
            ref={prov.innerRef}
          >
            {headerContent && (
              <div className="list-header shadow-sm">{headerContent}</div>
            )}
            {!!items.length &&
              items.map((it, idx) => (
                <Draggable key={it.id} index={idx} draggableId={it.id}>
                  {(prov, snap) => (
                    <li
                      ref={prov.innerRef}
                      {...prov.draggableProps}
                      {...prov.dragHandleProps}
                      className={`preview-list-item ${
                        activeItem?.id === it.id ? "active" : ""
                      } ${snap.isDragging ? "dragging" : ""}`}
                      onClick={() => {
                        onSelect(it);
                      }}
                      onContextMenu={
                        allowDelete && activeItem?.id === it?.id
                          ? showDeleteIcon === it.id
                            ? (e) => {
                                e.preventDefault();
                                setShowDeleteIcon(null);
                              }
                            : (e) => {
                                e.preventDefault();
                                setShowDeleteIcon(it.id);
                              }
                          : undefined
                      }
                    >
                      {!snap.isDragging && (
                        <strong
                          className="slide-index"
                          style={{ fontSize: 12 }}
                        >
                          {idx + 1}
                        </strong>
                      )}
                      <div
                        style={{
                          opacity: snap.isDragging ? "0.7" : "1",
                          position: "relative"
                        }}
                      >
                        {!!(showDeleteIcon === it.id && onDelete) && (
                          <div
                            className="delete-icon"
                            onClick={() => onDelete(it)}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </div>
                        )}
                        <SlideThumb src={it.src} className="slide-thumbnail" />
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
            {prov.placeholder}
            {!!(additionalActionTile && items.length) && (
              <li
                className="additional-list-item"
                onClick={additionalActionTile.onClick}
              >
                <div className="additional-sidebar-tile">
                  {additionalActionTile.title}
                </div>
              </li>
            )}
          </ul>
        </>
      )}
    </Droppable>
  ) : (
    <ul
      className="sidebar-list ps-0"
      style={headerContent ? { paddingTop: 55 } : undefined}
    >
      {headerContent && (
        <div className="list-header shadow-sm">{headerContent}</div>
      )}
      {!!items.length &&
        items.map((it, idx) => (
          <li
            key={idx}
            className={`preview-list-item ${
              activeItem?.id === it.id ? "active" : ""
            }`}
            onClick={() => {
              onSelect(it);
            }}
          >
            <strong className="slide-index" style={{ fontSize: 12 }}>
              {idx + 1}
            </strong>
            <div
              style={{
                position: "relative"
              }}
            >
              {!!(showDeleteIcon === it.id && onDelete) && (
                <div className="delete-icon" onClick={() => onDelete(it)}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              )}
              <SlideThumb src={it.src} className="slide-thumbnail" />
            </div>
          </li>
        ))}
      {!!(additionalActionTile && items.length) && (
        <li
          className="additional-list-item"
          onClick={additionalActionTile.onClick}
        >
          <div className="additional-sidebar-tile">
            {additionalActionTile.title}
          </div>
        </li>
      )}
    </ul>
  );
}

export default Navbar;
