import { Redirect, useRouteMatch } from "react-router-dom";
import { useAuth } from "../context/auth/provider";

function Auth() {
  const { user, login } = useAuth();
  const match = useRouteMatch();
  const { token } = match.params as { token: string };

  // eslint-disable-next-line no-undef
  let isTokenValid = token === process.env.REACT_APP_INVITE_TOKEN;

  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <div
      style={{ height: "100vh", width: "100vw", backgroundColor: "#333" }}
      className="d-flex align-items-center justify-content-center"
    >
      <button
        className="btn btn-primary d-flex align-items-center bg-white border-0 shadow text-dark px-5 py-2"
        onClick={() => login(isTokenValid)}
      >
        <img
          src="https://img.icons8.com/color/96/000000/google-logo.png"
          alt="google icon"
          style={{ width: 30 }}
        />
        <span style={{ fontSize: "1.1rem", marginLeft: 10 }}>
          Mit Google fortsetzen
        </span>
      </button>
    </div>
  );
}

export default Auth;
