import { useCallback, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Header from "../../components/Header";
import ViewLayout from "../../components/layout";
import Toolbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import SlideView from "../../components/layout/SlideView";
import PresentationSelect from "../../components/PresentationSelect";
import {
  useDispatchContext,
  useStateContext
} from "../../context/state/provider";
import EditorSlide from "../../components/EditorSlide";
import { CustomBuiltSlideData } from "../../types";
import EditorSidebar from "../../components/EditorSidebar";

function Editor() {
  const {
    state: {
      editor: {
        customSlide,
        selectedCustomPresentation,
        selectedCustomPresentationSlides
      }
    }
  } = useStateContext();
  const { dispatch } = useDispatchContext();

  const handleDragEnd = useCallback(() => {}, []);

  const handleDragStart = useCallback(() => {}, []);

  if (
    !customSlide ||
    !selectedCustomPresentation ||
    !selectedCustomPresentationSlides
  )
    return null;
  return (
    <>
      <Header />
      <ViewLayout>
        <Toolbar
          mainContent={
            <PresentationSelect
              items={[selectedCustomPresentation]}
              selectedItem={selectedCustomPresentation}
              onSelect={() => {}}
            />
          }
        />
        <Sidebar
          items={selectedCustomPresentationSlides}
          activeItem={
            selectedCustomPresentationSlides.find(
              (s) => s.id === customSlide.id
            ) as CustomBuiltSlideData
          }
          withDrop={false}
          isDropDisabled={true}
          onSelect={() => {}}
        />
        <DragDropContext
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <SlideView>
            <EditorSlide />
            <EditorSidebar />
          </SlideView>
        </DragDropContext>
      </ViewLayout>
    </>
  );
}

export default Editor;
