import "./index.css";

import React from "react";
import { LayoutProvider } from "../../context/layout";

const ViewLayout: React.FC<React.PropsWithChildren<{ width?: number }>> = ({
  children,
  width = 100
}) => {
  return (
    <LayoutProvider>
      <div className="grid-container" style={{ width: `${width}vw` }}>
        {children}
      </div>
    </LayoutProvider>
  );
};

export default ViewLayout;
