import React, { createContext, useCallback, useContext, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import { presentationBuilderReducer, editorReducer } from "./reducers";
import { State, Action } from "./types";

const combineDispatch =
  (...dispatches: React.Dispatch<Action>[]) =>
  (action: Action) =>
    dispatches.forEach((dispatch) => dispatch(action));

export const initialState: State = {
  presentationBuilder: {
    masterPresentations: [],
    selectedMasterPresentation: null,
    selectedMasterPresentationSlides: [],
    selectedMasterSlide: null,
    customPresentations: [],
    selectedCustomPresentation: null,
    selectedCustomPresentationSlides: [],
    selectedCustomSlide: null,
    newCustomPresentation: null,
    newCustomPresentationSlides: [],
    modalData: null
  },
  editor: {
    selectedCustomPresentation: null,
    selectedCustomPresentationSlides: [],
    customSlide: null,
    selectedSlideComponent: null
  }
};

type StateContextType = {
  state: State;
};

type DispatchContextType = {
  dispatch: React.Dispatch<Action>;
};

const StateContext = createContext<StateContextType>({
  state: initialState
});

const DispatchContext = createContext<DispatchContextType>({
  dispatch: () => {
    return;
  }
});

export const useStateContext = () => useContext(StateContext);
export const useDispatchContext = () => useContext(DispatchContext);

export const StateProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  const [presentationBuilderState, presentationBuilderDispatch] =
    useImmerReducer(
      presentationBuilderReducer,
      initialState.presentationBuilder
    );
  const [editorState, editorDispatch] = useImmerReducer(
    editorReducer,
    initialState.editor
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useCallback(
    combineDispatch(presentationBuilderDispatch, editorDispatch),
    [presentationBuilderDispatch, editorDispatch]
  );
  const state = useMemo(
    () => ({
      presentationBuilder: presentationBuilderState,
      editor: editorState
    }),
    [presentationBuilderState, editorState]
  );

  return (
    <DispatchContext.Provider value={{ dispatch }}>
      <StateContext.Provider value={{ state }}>
        {children}
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export default StateProvider;
