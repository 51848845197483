import {
  faChevronDoubleRight,
  faChevronDoubleLeft
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useLayout } from "../../context/layout";

type SlideViewProps = {
  withDeleteZone?: boolean;
  showDeleteZone?: boolean;
  allowToggleSidebar?: boolean;
};

// eslint-disable-next-line no-undef
const SlideView: React.FC<React.PropsWithChildren<SlideViewProps>> = ({
  children,
  withDeleteZone = false,
  showDeleteZone,
  allowToggleSidebar = false
}) => {
  const { hideSidebar, setHideSidebar } = useLayout();

  let style: CSSProperties = {
    height: "100%",
    width: "100%",
    opacity: 0,
    pointerEvents: "none",
    backgroundColor: "rgb(247, 159, 159)",
    border: "5px dashed rgb(255, 73, 73)"
  };
  if (showDeleteZone) {
    style.opacity = 1;
    style.pointerEvents = "all";
  }
  return (
    <div className={`slide-view ${hideSidebar ? "expended" : ""}`}>
      {allowToggleSidebar && (
        <button
          className="btn btn-outline-secondary p-0"
          style={{
            fontSize: "0.9rem",
            position: "absolute",
            width: 25,
            height: 25,
            left: 10,
            top: 10
          }}
          onClick={() => setHideSidebar((prev) => !prev)}
        >
          <FontAwesomeIcon
            icon={hideSidebar ? faChevronDoubleRight : faChevronDoubleLeft}
          />
        </button>
      )}
      {children}
      {/* {src && <img src={src} style={{ width: "90%" }} className="shadow-lg" />} */}
      <div className="delete-zone-outter">
        <p
          className={`zone-title ${
            showDeleteZone && withDeleteZone ? "show" : ""
          }`}
        >
          Folie löschen
        </p>
        <Droppable droppableId="deleteZone">
          {(prov, snap) => (
            <div
              ref={prov.innerRef}
              style={style}
              className={`${showDeleteZone && withDeleteZone ? "show" : ""}`}
            >
              {prov.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default SlideView;
