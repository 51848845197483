import "./index.css";

function TransformationFrame({
  handleResizeStart
}: {
  handleResizeStart: (e: any) => void;
}) {
  return (
    <>
      <div
        className="draggable-resize-handle draggable-resize-handle-nw"
        data-handle="nw"
        onMouseDown={handleResizeStart}
      />
      <div
        className="draggable-resize-handle draggable-resize-handle-n"
        data-handle="n"
        onMouseDown={handleResizeStart}
      />
      <div
        className="draggable-resize-handle draggable-resize-handle-ne"
        data-handle="ne"
        onMouseDown={handleResizeStart}
      />
      <div
        className="draggable-resize-handle draggable-resize-handle-e"
        data-handle="e"
        onMouseDown={handleResizeStart}
      />
      <div
        className="draggable-resize-handle draggable-resize-handle-se"
        data-handle="se"
        onMouseDown={handleResizeStart}
      />
      <div
        className="draggable-resize-handle draggable-resize-handle-s"
        data-handle="s"
        onMouseDown={handleResizeStart}
      />
      <div
        className="draggable-resize-handle draggable-resize-handle-sw"
        data-handle="sw"
        onMouseDown={handleResizeStart}
      />
      <div
        className="draggable-resize-handle draggable-resize-handle-w"
        data-handle="w"
        onMouseDown={handleResizeStart}
      />
    </>
  );
}

export default TransformationFrame;
