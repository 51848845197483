import React from "react";
import { ClipboardEvent, useRef, FC } from "react";
import { TextData } from "../types";
import "./text.css";

type TextProps = {
  data: TextData;
  scale: number;
  children: React.ReactNode;
  dimensions: {
    x: number;
    y: number;
  } | null;
  style: React.CSSProperties;
  transform: {
    scale: number;
    rotate: number;
  } | null;
  [key: string]: any;
};

const TextWrapper = ({
  data,
  children,
  scale,
  style,
  dimensions,
  transform
}: TextProps) => (
  <div
    style={{
      width: "100%",
      display: "inline-block",
      transform: `scale(${scale}, ${scale})`
    }}
  >
    <div
      className={`text-wrapper text-${data.horizontalAlign}`}
      style={{
        ...style,
        ...(dimensions || {}),
        transform: `${
          transform?.rotate &&
          `rotate(${transform.rotate}deg) ${
            transform?.scale && `scale(${(transform.scale, transform.scale)})`
          }`
        }`
      }}
    >
      {children}
    </div>
  </div>
);

const handlePaste = (e: ClipboardEvent<HTMLSpanElement>) => {
  e.preventDefault();

  const text = e.clipboardData?.getData("text/plain");
  if (text) {
    document.execCommand("insertHTML", false, text);
  }
};

const Editable: FC<React.PropsWithChildren<TextProps>> = ({
  data,
  onChange
}) => {
  const nodeRef = useRef<HTMLSpanElement>(null);

  const handleChange = () => {
    if (!nodeRef.current) return;
    const node = nodeRef.current;
    onChange({ ...data, value: node.innerHTML });
  };

  return (
    <span
      ref={nodeRef}
      draggable={false}
      style={{ outline: "none" }}
      contentEditable
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onInput={handleChange}
      onPaste={handlePaste}
      dangerouslySetInnerHTML={{ __html: data.value }}
    />
  );
};

export const H1 = (props: TextProps) => (
  <TextWrapper {...props}>
    <h1>
      <Editable {...props} />
    </h1>
  </TextWrapper>
);

export const H2 = (props: TextProps) => (
  <TextWrapper {...props}>
    <h2>
      <Editable {...props} />
    </h2>
  </TextWrapper>
);

export const H3 = (props: TextProps) => (
  <TextWrapper {...props}>
    <h3>
      <Editable {...props} />
    </h3>
  </TextWrapper>
);

export const P = (props: TextProps) => (
  <TextWrapper {...props}>
    <p>
      <Editable {...props} />
    </p>
  </TextWrapper>
);
