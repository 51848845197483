import {
  faPlus,
  faChevronUp,
  faChevronDown
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import "./index.css";

/* eslint-disable no-undef */
type AccordionProps = {
  title: JSX.Element | string;
  content: {
    previewComponent: JSX.Element | string;
    onClick: () => void;
  }[];
};
const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = ({
  title,
  content
}) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <div className="accordion-wrapper">
      <div
        className="accordion-heading bg-light border"
        onClick={() => setIsOpened((prev) => !prev)}
      >
        {title}
        <FontAwesomeIcon
          icon={isOpened ? faChevronUp : faChevronDown}
          className="ms-2"
        />
      </div>

      <div className={`accordion-content  ${isOpened ? "opened" : ""}`}>
        {content.map(({ previewComponent, onClick }, idx) => (
          <div
            className="accordion-component bg-white border border-top-0"
            key={idx}
          >
            {previewComponent}
            <FontAwesomeIcon icon={faPlus} className="ms-2" onClick={onClick} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
