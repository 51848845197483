import { createRoot } from "react-dom/client";
import "./index.css";
import "@fortawesome/fontawesome-pro/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { StateProvider } from "./context/state/provider";
import { ApiFetchProvider } from "./context/api";
import { BrowserRouter as Router } from "react-router-dom";
import AuthContextProvider from "./context/auth/provider";

createRoot(document.getElementById("root")!).render(
  <Router>
    <ApiFetchProvider>
      <AuthContextProvider>
        <StateProvider>
          <App />
        </StateProvider>
      </AuthContextProvider>
    </ApiFetchProvider>
  </Router>
);
