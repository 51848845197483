import ViewLayout from "../../components/layout";
import Toolbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import SlideView from "../../components/layout/SlideView";
import {
  useDispatchContext,
  useStateContext
} from "../../context/state/provider";
import { FormEvent, useCallback, useEffect, useState } from "react";
import {
  CustomBuiltSlideData,
  CustomPresentationInput,
  CustomPresentationSlideData,
  PresentationData,
  SlideData,
  SlideType
} from "../../types";
import PresentationSelect from "../../components/PresentationSelect";
import { useApiFetch } from "../../context/api";
import { DragDropContext, DragStart, DropResult } from "react-beautiful-dnd";
import { v4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { usePrevious } from "react-use";
import Header from "../../components/Header";
import PortalModal from "../../components/Modal";
import { useHistory } from "react-router-dom";

function PresentationBuilder() {
  const [showDeleteZone, setShowDeleteZone] = useState(false);
  const history = useHistory();
  const {
    state: { presentationBuilder }
  } = useStateContext();
  const { dispatch } = useDispatchContext();
  const { fetchApi } = useApiFetch();
  const prevMaster = usePrevious(
    presentationBuilder.selectedMasterPresentation
  );
  const prevCustom = usePrevious(
    presentationBuilder.selectedCustomPresentation
  );

  const handleFetchMasterCollections = useCallback(async () => {
    const response = await fetchApi("/api/primary-presentation");
    const masterPresentations: PresentationData[] = await response.json();
    dispatch({
      type: "setMasterPresentations",
      data: masterPresentations.sort((a, b) =>
        a.title < b.title ? -1 : a.title > b.title ? 1 : 0
      )
    });
  }, [fetchApi, dispatch]);

  const handleFetchCustomCollections = useCallback(async () => {
    const response = await fetchApi("/api/secondary-presentation");
    const customPresentations: CustomPresentationInput[] =
      await response.json();
    dispatch({
      type: "setCustomPresentations",
      data: customPresentations.sort((a, b) =>
        a.title < b.title ? -1 : a.title > b.title ? 1 : 0
      )
    });
  }, [fetchApi, dispatch]);

  useEffect(() => {
    handleFetchCustomCollections();
    handleFetchMasterCollections();
  }, [handleFetchCustomCollections, handleFetchMasterCollections]);

  const handleFetchSelectedMasterSlides = useCallback(async () => {
    const currentPresentation = presentationBuilder.selectedMasterPresentation;
    if (!currentPresentation) return;
    const response = await fetchApi(
      `/api/primary-presentation/${currentPresentation.id}/slides`
    );
    const slides: SlideData[] = await response.json();
    let filteredSlides: SlideData[] = [];
    slides.forEach((slide) => {
      const foundSlide = filteredSlides.find(
        (s) => s.slideId === slide.slideId
      );
      if (foundSlide) {
        if (foundSlide.version < slide.version)
          filteredSlides = filteredSlides.map((sl) =>
            sl.slideId === foundSlide.slideId ? slide : sl
          );
        else return;
      } else filteredSlides.push(slide);
    });
    dispatch({
      type: "setMasterPresentationSlides",
      data: filteredSlides.sort((a, b) => a.masterIndex - b.masterIndex)
    });
    // eslint-disable-next-line
  }, [dispatch, fetchApi, presentationBuilder.selectedMasterPresentation]);

  const handleFetchSelectedCustomSlides = useCallback(async () => {
    const currentPresentation = presentationBuilder.selectedCustomPresentation;
    if (!currentPresentation) return;
    const response = await fetchApi(
      `/api/secondary-presentation/${currentPresentation.id}/slides`
    );
    const data: SlideData[] = await response.json();
    dispatch({
      type: "setCustomPresentationSlides",
      data: data.sort(
        (a, b) =>
          (currentPresentation.slides.find((s) => s.slideId === a.slideId)
            ?.orderIndex || 0) -
          (currentPresentation.slides.find((s) => s.slideId === b.slideId)
            ?.orderIndex || 0)
      )
    });
    // eslint-disable-next-line
  }, [dispatch, fetchApi, presentationBuilder.selectedCustomPresentation]);

  useEffect(() => {
    if (presentationBuilder.selectedMasterPresentation?.id === prevMaster?.id)
      return;
    handleFetchSelectedMasterSlides();
    // eslint-disable-next-line
  }, [
    handleFetchSelectedMasterSlides,
    presentationBuilder.selectedMasterPresentation
  ]);

  useEffect(() => {
    if (presentationBuilder.selectedCustomPresentation?.id === prevCustom?.id)
      return;
    handleFetchSelectedCustomSlides();
    // eslint-disable-next-line
  }, [
    handleFetchSelectedCustomSlides,
    presentationBuilder.selectedCustomPresentation
  ]);

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      const { source, destination, draggableId } = result;
      setShowDeleteZone(false);

      if (
        showDeleteZone &&
        source.droppableId === "customCol" &&
        destination?.droppableId === "deleteZone"
      ) {
        const elemToDelete =
          presentationBuilder.selectedCustomPresentationSlides.find(
            (s) => s.id === draggableId
          );
        if (elemToDelete) {
          const presentation = {
            ...presentationBuilder.selectedCustomPresentation
          } as CustomPresentationInput;
          let presentationSlides = [
            ...presentationBuilder.selectedCustomPresentationSlides
          ];
          if (presentation && presentation.slides) {
            presentation.slides = presentation.slides.filter(
              (sl) => sl.id !== elemToDelete.id
            );
            presentationSlides = presentationSlides.filter(
              (sl) => sl.id !== elemToDelete.id
            );
            const nextPresentations =
              presentationBuilder.customPresentations.map((pr) =>
                pr.id === presentation.id ? presentation : pr
              );
            dispatch({
              type: "setCustomPresentations",
              data: nextPresentations
            });
            dispatch({
              type: "setCustomPresentationSlides",
              data: presentationSlides
            });
            await fetchApi("/api/secondary-presentation/save", {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify(presentation)
            });
          }
        }
      }

      if (source.droppableId !== destination?.droppableId) {
        if (
          source.droppableId === "masterCol" &&
          destination?.droppableId === "customCol"
        ) {
          console.log("1. left -> right");
          const movedElem =
            presentationBuilder.selectedMasterPresentationSlides.find(
              (it) => it.id === draggableId
            ) as SlideData;
          const newItem: SlideData = {
            ...movedElem,
            id: v4(),
            masterIndex: destination.index
          };
          if (presentationBuilder.selectedCustomPresentation) {
            console.log("1.1 Extending custom presentation");
            const nextItems = [
              ...presentationBuilder.selectedCustomPresentationSlides
            ];
            nextItems.splice(destination.index, 0, newItem);
            dispatch({
              type: "setCustomPresentationSlides",
              data: nextItems
            });
            const presentation = {
              ...presentationBuilder.selectedCustomPresentation,
              slides: [...presentationBuilder.selectedCustomPresentation.slides]
            };

            presentation.slides = nextItems.map((i, idx) => ({
              slideId: i.slideId,
              id: i.id,
              orderIndex: idx
            }));
            presentation.thumbnail = nextItems[0].src;

            dispatch({
              type: "setSelectedCustomPresentation",
              data: presentation
            });
            dispatch({
              type: "setCustomPresentations",
              data: presentationBuilder.customPresentations.map((pr) =>
                pr.id === presentation.id ? presentation : pr
              )
            });
            await fetchApi("/api/secondary-presentation/save", {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify(presentation)
            });
          } else if (presentationBuilder.newCustomPresentation) {
            console.log("1.2 Extending new custom presentation");
            const nextItems = [
              ...presentationBuilder.newCustomPresentationSlides
            ];
            const id = v4();
            const newItem: SlideData = {
              ...movedElem,
              id,
              masterIndex: destination.index
            };

            nextItems.splice(destination.index, 0, newItem);
            const nextItemsRefs: CustomPresentationSlideData[] = nextItems.map(
              (i, idx) => ({
                slideId: i.slideId,
                id: i.id,
                orderIndex: idx
              })
            );
            dispatch({
              type: "setNewCustomPresentation",
              data: {
                ...presentationBuilder.newCustomPresentation,
                slides: nextItemsRefs
              }
            });
            dispatch({
              type: "setNewCustomPresentationSlides",
              data: nextItems
            });
          } else if (!presentationBuilder.newCustomPresentation) {
            console.log("1.3 Creating new custom presentation");
            const id = v4();
            const newItemRef: CustomPresentationSlideData = {
              id,
              slideId: movedElem.slideId,
              orderIndex: destination.index
            };
            const newItem: SlideData = {
              ...movedElem,
              id,
              masterIndex: destination.index
            };
            const newPresentation: CustomPresentationInput = {
              id: v4(),
              created: "",
              updated: "",
              title: "",
              thumbnail: movedElem.src,
              slides: [newItemRef]
            };
            const newPresentationSlides: SlideData[] = [newItem];
            dispatch({ type: "setSelectedCustomPresentation", data: null });
            dispatch({
              type: "setCustomPresentationSlides",
              data: []
            });

            dispatch({
              type: "setNewCustomPresentation",
              data: newPresentation
            });
            dispatch({
              type: "setNewCustomPresentationSlides",
              data: newPresentationSlides
            });
          }
        }
      } else if (
        source.droppableId === destination.droppableId &&
        source.droppableId === "customCol"
      ) {
        console.log("2. right -> right");

        if (presentationBuilder.selectedCustomPresentation) {
          console.log("2.1 shuffling custom presentation");

          const movedElem =
            presentationBuilder.selectedCustomPresentationSlides.find(
              (it) => it.id === draggableId
            ) as SlideData;
          const presentation = {
            ...presentationBuilder.selectedCustomPresentation
          } as CustomPresentationInput;

          let customPresentationSlidesCopy = [
            ...presentationBuilder.selectedCustomPresentationSlides
          ];
          customPresentationSlidesCopy = customPresentationSlidesCopy.filter(
            (it) => it.id !== movedElem.id
          );
          customPresentationSlidesCopy.splice(destination.index, 0, movedElem);
          presentation.slides = customPresentationSlidesCopy.map((it, idx) => ({
            id: it.id,
            orderIndex: idx,
            slideId: it.slideId
          }));
          dispatch({
            type: "setCustomPresentationSlides",
            data: customPresentationSlidesCopy
          });
          presentation.thumbnail = customPresentationSlidesCopy[0].src;
          console.log(
            presentationBuilder.customPresentations.find(
              (pr) => pr.id === presentation.id
            )
          );
          dispatch({
            type: "setCustomPresentations",
            data: presentationBuilder.customPresentations.map((pr) =>
              pr.id === presentation.id ? presentation : pr
            )
          });
          await fetchApi("/api/secondary-presentation/save", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(presentation)
          });
          dispatch({
            type: "setSelectedCustomPresentation",
            data: presentation
          });
        } else if (presentationBuilder.newCustomPresentation) {
          console.log("2.1 shuffling new custom presentation");

          const movedElem =
            presentationBuilder.newCustomPresentationSlides.find(
              (it) => it.id === draggableId
            ) as SlideData;
          const presentation = {
            ...presentationBuilder.newCustomPresentation
          } as CustomPresentationInput;

          let customPresentationSlidesCopy = [
            ...presentationBuilder.newCustomPresentationSlides
          ];
          customPresentationSlidesCopy = customPresentationSlidesCopy.filter(
            (it) => it.id !== movedElem.id
          );
          customPresentationSlidesCopy.splice(destination.index, 0, movedElem);
          presentation.slides = customPresentationSlidesCopy.map((it, idx) => ({
            id: it.id,
            orderIndex: idx,
            slideId: it.slideId
          }));
          dispatch({
            type: "setNewCustomPresentationSlides",
            data: customPresentationSlidesCopy
          });
          dispatch({ type: "setNewCustomPresentation", data: presentation });
        }
      }
    },
    [
      dispatch,
      fetchApi,
      showDeleteZone,
      presentationBuilder.customPresentations,
      presentationBuilder.newCustomPresentation,
      presentationBuilder.newCustomPresentationSlides,
      presentationBuilder.selectedCustomPresentation,
      presentationBuilder.selectedCustomPresentationSlides,
      presentationBuilder.selectedMasterPresentationSlides
    ]
  );

  const handleSaveNewPresentation = useCallback(
    async (newPresentation: CustomPresentationInput) => {
      await fetchApi("/api/secondary-presentation/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(newPresentation)
      });
      dispatch({
        type: "setCustomPresentations",
        data: [...presentationBuilder.customPresentations, newPresentation]
      });
      dispatch({
        type: "setSelectedCustomPresentation",
        data: newPresentation
      });
      dispatch({
        type: "setNewCustomPresentationSlides",
        data: presentationBuilder.newCustomPresentationSlides
      });
      dispatch({ type: "setNewCustomPresentation", data: null });
      dispatch({
        type: "setNewCustomPresentationSlides",
        data: []
      });
    },
    [
      dispatch,
      fetchApi,
      presentationBuilder.customPresentations,
      presentationBuilder.newCustomPresentationSlides
    ]
  );

  const handleCreatePresentation = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // @ts-ignore
    const title = e.target["presentation-title"].value as string;
    console.log(title);
    if (!title.length) return;
    const newPresentation = {
      ...presentationBuilder.newCustomPresentation
    } as CustomPresentationInput;
    newPresentation.created = new Date().toISOString();
    newPresentation.updated = new Date().toISOString();
    newPresentation.title = title;
    newPresentation.thumbnail =
      presentationBuilder.newCustomPresentationSlides.find(
        (it) => it.slideId === newPresentation.slides[0].slideId
      )?.src as string;
    await handleSaveNewPresentation(newPresentation);
  };

  const handleCreateMode = () => {
    dispatch({ type: "setSelectedCustomPresentation", data: null });
    dispatch({ type: "setSelectedCustomPresentationSlide", data: null });
    dispatch({ type: "setCustomPresentationSlides", data: [] });
    const newPresentation: CustomPresentationInput = {
      id: v4(),
      created: "",
      updated: "",
      title: "",
      thumbnail: "",
      slides: []
    };
    dispatch({ type: "setNewCustomPresentation", data: newPresentation });
  };

  const handleCancelCreation = () => {
    dispatch({ type: "setNewCustomPresentation", data: null });
    dispatch({ type: "setNewCustomPresentationSlides", data: [] });
  };

  const handleDragStart = useCallback(
    (initial: DragStart) => {
      if (initial.source.droppableId === "customCol" && !showDeleteZone) {
        setShowDeleteZone(true);
      }
    },
    [showDeleteZone]
  );

  const handleDeleteCustomSlide = useCallback(
    async (slide: SlideData | CustomBuiltSlideData) => {
      const presentation = {
        ...presentationBuilder.selectedCustomPresentation
      } as CustomPresentationInput;
      let presentationSlides = [
        ...presentationBuilder.selectedCustomPresentationSlides
      ];
      if (presentation && presentation.slides) {
        presentation.slides = presentation.slides.filter(
          (sl) => sl.id !== slide.id
        );
        presentationSlides = presentationSlides.filter(
          (sl) => sl.id !== slide.id
        );
        const nextPresentations = presentationBuilder.customPresentations.map(
          (pr) => (pr.id === presentation.id ? presentation : pr)
        );
        dispatch({
          type: "setCustomPresentations",
          data: nextPresentations
        });
        dispatch({
          type: "setCustomPresentationSlides",
          data: presentationSlides
        });
        await fetchApi("/api/secondary-presentation/save", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(presentation)
        });
      }
    },
    [
      dispatch,
      fetchApi,
      presentationBuilder.customPresentations,
      presentationBuilder.selectedCustomPresentation,
      presentationBuilder.selectedCustomPresentationSlides
    ]
  );

  const handleCopyCustomPresentation = useCallback(
    async (item: PresentationData | CustomPresentationInput) => {
      const presentation = {
        ...item,
        title: item.title.concat(" (Kopie)"),
        slides: [...(item as CustomPresentationInput).slides],
        id: v4(),
        created: new Date().toISOString(),
        updated: new Date().toISOString()
      } as CustomPresentationInput;
      await handleSaveNewPresentation(presentation);
    },
    [handleSaveNewPresentation]
  );

  const handleDeleteCustomPresentation = useCallback(
    async (item: PresentationData | CustomPresentationInput) => {
      const presentation = item as CustomPresentationInput;
      console.log(presentation);
      await fetchApi(`/api/secondary-presentation/${presentation.id}/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      });
      dispatch({ type: "setSelectedCustomPresentation", data: null });
      dispatch({ type: "setSelectedCustomPresentationSlide", data: null });
      dispatch({ type: "setCustomPresentationSlides", data: [] });
      dispatch({
        type: "setCustomPresentations",
        data: presentationBuilder.customPresentations.filter(
          (pr) => pr.id !== presentation.id
        )
      });
    },
    [dispatch, fetchApi, presentationBuilder.customPresentations]
  );

  const handleShowConfirmDeleteModal = useCallback(
    (item: PresentationData | CustomPresentationInput) => {
      console.log("here");
      dispatch({
        type: "setShowConfirmationModal",
        data: {
          headerText: "Präsentation löschen?",
          mainText:
            "Sind die sicher, dass Sie die gewählte Präsentation löschen wollen?",
          onConfirm: async () => {
            await handleDeleteCustomPresentation(item);
            dispatch({ type: "setHideConfirmationModal" });
          }
        }
      });
    },
    [dispatch, handleDeleteCustomPresentation]
  );

  const handleEditCustomPresentationEdit = useCallback(
    async (
      item: PresentationData | CustomPresentationInput,
      newTitle: string
    ) => {
      if (!newTitle.length) return;
      const itemCopy = { ...item } as CustomPresentationInput;
      itemCopy.title = newTitle;
      await fetchApi(`/api/secondary-presentation/rename`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(itemCopy)
      });
      dispatch({
        type: "setCustomPresentations",
        data: presentationBuilder.customPresentations.map((pr) =>
          pr.id === itemCopy.id ? itemCopy : pr
        )
      });
    },
    [dispatch, fetchApi, presentationBuilder.customPresentations]
  );

  const initNewEditorSlide = useCallback(
    (selectedCustomPresentation: CustomPresentationInput) => {
      const id = v4();
      const newSlide: CustomBuiltSlideData = {
        id,
        slideId: id,
        createdAt: "",
        updatedAt: "",
        components: [],
        initialSlideDimensions: null,
        src: "https://mvz-bietigheim.de/wp-content/uploads/2017/05/placeholder-image10.jpg",
        type: SlideType.CONSTRUCTED
      };
      const selectedCustomPresentationSlides =
        presentationBuilder.selectedCustomPresentationSlides;
      dispatch({
        type: "initEditorSlide",
        data: {
          selectedCustomPresentation,
          selectedCustomPresentationSlides,
          customSlide: newSlide,
          selectedSlideComponent: null
        }
      });
      history.push("/editor");
    },
    [dispatch, history, presentationBuilder.selectedCustomPresentationSlides]
  );

  return (
    <>
      <Header />
      <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <div className="d-flex">
          <ViewLayout width={50}>
            <Toolbar
              mainContent={
                <PresentationSelect
                  items={presentationBuilder.masterPresentations}
                  selectedItem={presentationBuilder.selectedMasterPresentation}
                  onSelect={(item) => {
                    dispatch({
                      type: "setSelectedMasterPresentation",
                      data: item as PresentationData
                    });
                  }}
                  additionalActionTile={{
                    title: "aktualisieren",
                    onClick: handleFetchMasterCollections
                  }}
                />
              }
            />
            <Sidebar
              items={presentationBuilder.selectedMasterPresentationSlides}
              activeItem={presentationBuilder.selectedMasterSlide}
              droppableId={"masterCol"}
              isDropDisabled={true}
              headerContent={
                presentationBuilder.selectedMasterPresentation && (
                  <button
                    className="btn btn-outline-secondary p-1"
                    style={{ height: 35 }}
                    onClick={handleFetchSelectedMasterSlides}
                  >
                    Liste aktualisieren
                  </button>
                )
              }
              onSelect={(item) => {
                dispatch({
                  type: "setSelectedMasterPresentationSlide",
                  data: item as SlideData
                });
              }}
            />

            <SlideView>
              <img
                src={presentationBuilder.selectedMasterSlide?.src}
                style={{ width: "90%" }}
                className="shadow-lg"
              />
            </SlideView>
          </ViewLayout>
          <ViewLayout width={50}>
            <Toolbar
              mainContent={
                <>
                  {presentationBuilder.newCustomPresentation &&
                  !presentationBuilder.selectedCustomPresentation ? (
                    <form
                      className="flex-column d-flex m-0"
                      onSubmit={handleCreatePresentation}
                    >
                      <div>
                        <input
                          type="text"
                          name="presentation-title"
                          placeholder="Namen eingeben"
                          className="form-control"
                        />
                      </div>
                      <div className="d-flex mt-2">
                        <button
                          type="submit"
                          className="btn btn-primary d-flex justify-content-center align-items-center"
                        >
                          <span className="me-2">Speichern</span>
                          <FontAwesomeIcon icon={faSave} />
                        </button>
                        <button
                          className="btn btn-secondary d-flex justify-content-center align-items-center ms-2"
                          onClick={handleCancelCreation}
                        >
                          <span className="me-2">Abbrechen</span>
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                    </form>
                  ) : (
                    <PresentationSelect
                      items={presentationBuilder.customPresentations}
                      selectedItem={
                        presentationBuilder.selectedCustomPresentation
                      }
                      onSelect={(item) => {
                        dispatch({
                          type: "setSelectedCustomPresentation",
                          data: item as CustomPresentationInput
                        });
                      }}
                      additionalActionTile={{
                        title: "Collection erstellen",
                        onClick: handleCreateMode
                      }}
                      itemType="pill"
                      onCopy={handleCopyCustomPresentation}
                      onDelete={handleShowConfirmDeleteModal}
                      onTitleEdit={handleEditCustomPresentationEdit}
                    />
                  )}
                </>
              }
            />
            <Sidebar
              items={
                presentationBuilder.selectedCustomPresentation
                  ? presentationBuilder.selectedCustomPresentationSlides
                  : presentationBuilder.newCustomPresentationSlides
              }
              activeItem={presentationBuilder.selectedCustomSlide}
              additionalActionTile={{
                title: "Slide erstellen",
                onClick: () => {
                  presentationBuilder.selectedCustomPresentation &&
                    initNewEditorSlide(
                      presentationBuilder.selectedCustomPresentation
                    );
                }
              }}
              droppableId={"customCol"}
              isDropDisabled={false}
              allowDelete
              onDelete={handleDeleteCustomSlide}
              headerContent={
                presentationBuilder.selectedCustomPresentation && (
                  <button
                    className="btn btn-outline-secondary p-1"
                    style={{ height: 35 }}
                    onClick={handleFetchSelectedCustomSlides}
                  >
                    Liste aktualisieren
                  </button>
                )
              }
              onSelect={(item) => {
                dispatch({
                  type: "setSelectedCustomPresentationSlide",
                  data: item
                });
              }}
            />
            <SlideView
              showDeleteZone={showDeleteZone}
              allowToggleSidebar
              withDeleteZone
            >
              <img
                src={presentationBuilder.selectedCustomSlide?.src}
                style={{ width: "90%" }}
                className="shadow-lg"
              />
            </SlideView>
          </ViewLayout>
        </div>
      </DragDropContext>
      {presentationBuilder.modalData && <PortalModal />}
    </>
  );
}

export default PresentationBuilder;
